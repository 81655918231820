import { useState } from "react";
import AddProductContext from "./addProductContext.ts";

const AddProductContextProvider = ({ children }) => {

    const [productData, setProductData] = useState({
        name: '',
        shop: '',
        category: '',
        subCategory: '',
        description: '',
        featuredImage: new Blob(),
        tags: [],
        variants: [],
    });

    const variantsData = productData.variants;
    const setVariantsData = (data) => {
        setProductData({ ...productData, variants: data });
    };

    return (
        <AddProductContext.Provider value={{ productData, setProductData, variantsData, setVariantsData }}>
            {children}
        </AddProductContext.Provider>
    );
};

export default AddProductContextProvider;