import React, { useEffect, useState } from "react";
import ColorButtonFilter from "../../../../../generic components/filter/filter components/Filter buttons/ColorButton";
import "../../../../../index.css";
import AddSize from "./add size/AddSize";
import classes from "./addVariants.module.css";

import { useContext } from "react";
import AddProductContext from "../../../../../contexts/addProductContext.ts";

import { colors, sizes } from "../../../../../assets/productData";

import UploadImage from "../../../../../generic components/uploadImage/uploadImage.js";

function AddVariants(props) {

  const { variantsData, setVariantsData } = useContext(AddProductContext);


  var variantData = variantsData[props.variantNo];
  const setVariant = (newData) => {
    variantsData[props.variantNo] = { ...variantsData[props.variantNo], ...newData };
    setVariantsData([...variantsData]);
  };

  const setImage = (image) => {
    console.log(image);

    setVariant({ images: [...variantsData[props.variantNo].images, image] });
  };


  const handleClickColor = (index) => {
    if (index === variantData.colorIndex) {
      setVariant({ colorIndex: null });
    } else {
      setVariant({ colorIndex: index });
    }
  };



  return (
    <div className={classes.container}>

      <div className={classes.uploadImage}>
        <UploadImage aspectRatio={1} setImage={setImage} />
      </div>
      <div>
        {variantData?.images?.map((image, index) => (
          <img src={URL.createObjectURL(image)} key={index} />
        ))}
      </div>
      <div className={classes.colors}>
        {colors.map((color, index) => (
          <ColorButtonFilter
            key={index}
            color={color.color}
            selected={index === (variantData.colorIndex ?? 0)}
            handleSelected={() => handleClickColor(index)}
          />
        ))}
      </div>
      {sizes.map((size, index) => (
        <AddSize
          variantIndex={props.variantNo}
          sizeIndex={index}
          key={index}
          size={size.size}
          priceName={size.size + String(props?.variantNo)}
          quantityName={size.size + String(props?.variantNo)}
        />
      ))}


    </div>
  );
}
export default AddVariants;
