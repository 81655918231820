import { Field, Form, Formik } from "formik";
import React from "react";
import classes from "./subscribe.module.css";
import "../../../index.css";
import { Navigate } from "react-router-dom";
const Subscribe = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  };

  const handleSubmit = (data) => {
    window.location.href = `mailto:elzahy@gmail.com?subject=elzahy website&
                            body=Name: ${data.firstName} ${data.lastName} \n
                            Email: ${data.email} \n 
                            Phone Number: ${data.phoneNumber}`;
  };

  return (
    <div className={classes.container}>
      <div className={classes.leftSection}>
        <div className={classes.leftSectionContent}>
          <h1 className={classes.header}>Contact Us</h1>
        </div>
      </div>
      <div className={classes.rightSection}>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values }) => (
            <Form>
              <div className={classes.boxContainer}>
                <div className={classes.nameFields}>
                  <div className={classes.name}>
                    <Field
                      className="inputField"
                      placeholder="First Name"
                      name="firstName"
                      type="text"
                      autoComplete="off"
                    />
                  </div>
                  <div className={classes.name}>
                    <Field
                      className="inputField"
                      placeholder="Last Name"
                      name="lastName"
                      type="text"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <Field
                  className="inputField"
                  placeholder="Email"
                  name="email"
                  type="email"
                  autoComplete="off"
                />
                <Field
                  className="inputField"
                  placeholder="Phone Number"
                  name="phoneNumber"
                  type="text"
                  autoComplete="off"
                />
              </div>

              <div className={classes.btn}>
                <button type="submit" className={classes.button}>
                  <p>Submit</p>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Subscribe;
