import classes from "./dashboard.module.css";
import StatCard from "./statCard/StatCard";
import heart from "../../../assets/imgs/icons/heart.svg";
import Chart from "./charts/Chart";
import chartData from "../../../assets/data/chartData";


const data = [
  {
    title: "Total Investors",
    icon: heart,
    number: [100, 200, 300, 500, 600, 800, 1000, 925],
    link: "/admin/",
  },
  {
    title: "Total Stocks",
    icon: heart,
    number: [1000, 2000, 3000, 4000, 5000, 6000, 5000, 4000, 3000, 5000],
    link: "/admin/investors",
  },
  {
    title: "Total Income",
    icon: heart,
    number: [
      190000, 203000, 312000, 400010, 500200, 620000, 59000, 900412, 590000,
      396040,
    ],
    link: "/admin/stocks",
  },
  {
    title: "Total Users",
    icon: heart,
    number: [100, 200, 300, 500, 600, 800, 1000, 975],
    link: "/admin/stocks",
  },
];

function Dashboard() {
  return (
    <div className={classes.dashboard}>
      <div className={classes.stats}>
        {data.map((stat, index) => (
          <StatCard stat={stat} key={index} />
        ))}
      </div>
      <div className={classes.charts}>
        {chartData.map((chart, index) => (
          <Chart
            type={chart.type}
            data={chart.data}
            width={"70%"}
            xKey="date"
            yKey="number"
            key={index}
          />
        ))}
      </div>
    </div>
  );
}

export default Dashboard;