import { useState, useEffect } from "react";
import classes from "./adminOrders.module.css";
import CollapsiveOrders from "../../../generic components/collapsiveOrder/CollapsiveOrder";
import EmptyOrder from "../../profile/Orders/empty order/EmptyOrder";
import axios from "../../../requests/axios";
import routes from "../../../requests/routes";
import "../../../index.css";
import Loader from "../../../layouts/loader/Loader";

function AdminOrders() {
  const [orders, setOrders] = useState([]);
  const [loader, setLoader] = useState(true);

  async function getOrders() {
    try {
      const resp = await axios.get(routes.getAllOrdersAdmin);
      setOrders(resp.data.orders);
      setLoader(false);
    } catch (err) {}
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getOrders();
  }, []);

  return (
    <div className={classes.main}>
      <h1 className="title">Orders</h1>
      {loader ? (
        <Loader color={"#f900bf"} />
      ) : (
        <>
          {orders.length === 0 ? (
            <EmptyOrder admin={true} />
          ) : (
            <>
              {orders.map((order, index) => (
                <CollapsiveOrders key={index} order={order} admin={true} />
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default AdminOrders;
