import { Link, NavLink } from "react-router-dom";
import classes from "./sideBar.module.css";
import { useDispatch } from "react-redux";
import { userActions } from "../../../store/userSlice";
import { useNavigate } from "react-router-dom";

function SideBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = [
    {
      title: "Dashboard",
      link: "dashboard",
    },
    {
      title: "Orders",
      link: "orders",
    },
    {
      title: "Products",
      link: "products",
    },
  ];

  const logoutHandler = () => {
    dispatch(userActions.logout());
    navigate("/login");
  };

  return (
    <>
      <div className={classes.header}>
        {/* <h3>Hi ,</h3> */}
        {/* <p>{user.email}</p> */}
      </div>
      <ul className={classes.nav}>
        {data.map((item, index) => (
          <li key={index}>
            <NavLink
              // end
              to={item.link}
              className={({ isActive }) =>
                `${classes.navLink} ${isActive ? classes.active : ""}`
              }
            >
              {item.title}
            </NavLink>
          </li>
        ))}
        <li>
          <div onClick={logoutHandler}>Logout</div>
        </li>
      </ul>
    </>
  );
}

export default SideBar;
