import React from 'react';
import { colors, sizes } from '../assets/productData';

interface variantSize {
    size: string;
    price: number;
    quantity: number;
}
interface Variant {
    colorIndex: number | null;
    sizes: variantSize[];
    images: Blob[];
}

interface Product {
    name: string;
    shop: string;
    category: string;
    subCategory: string;
    description: string;
    featuredImage: Blob;
    tags: string[];
    variants: Variant[];
}

const AddProductContext = React.createContext<Product>({
    name: '',
    shop: '',
    category: '',
    subCategory: '',
    description: '',
    featuredImage: new Blob(),
    tags: [],
    variants: [],
});

export default AddProductContext;

export function createVariant() {
    const variant: Variant = {
        colorIndex: null,
        images: [],
        sizes: sizes.map((sizeOption) => {
            const size: variantSize = {
                size: sizeOption.size,
                price: 0,
                quantity: 0,
            };
            return size;
        }),
    };
    return variant;
}