import Molokhia from "../imgs/products/Molokhia.png";
import Mango from "../imgs/products/Mango.png";
import Okra from "../imgs/products/Okra zero.png";
import Mixed from "../imgs/products/Mixed Vegetables.png";
import strawberry from "../imgs/products/Strawberry.png";
import pnc from "../imgs/products/Peas & Carrots.png";
import peas from "../imgs/products/Green Peas.png";
import beans from "../imgs/products/Green Beans.png";
import fries from "../imgs/products/French Fries.jpg";


const productData = [
  {
    "name": "Molokhia",
    "variants": [
      {
        "price": 0,
      },
    ],
    "featuredImage": Molokhia,
  },
  {
    "name": "Mango",
    "variants": [
      {
        "price": 0,
      },
    ],
    "featuredImage": Mango,
  },
  {
    "name": "Okra",
    "variants": [
      {
        "price": 0,
      },
    ],

    "featuredImage": Okra,
  },
  {
    "name": "Mixed Vegetables",
    "variants": [
      {
        "price": 0,
      },
    ],
    "featuredImage": Mixed,
  },
  {
    "name": "Strawberry",
    "variants": [
      {
        "price": 0,
      },
    ],
    "featuredImage": strawberry,
  },
  {
    "name": "Peas & Carrots",
    "variants": [
      {
        "price": 0,
      },
    ],
    "featuredImage": pnc,
  },
  {
    "name": "Green Peas",
    "variants": [
      {
        "price": 0,
      },
    ],
    "featuredImage": peas,
  },
  {
    "name": "Green Beans",
    "variants": [
      {
        "price": 0,
      },
    ],
    "featuredImage": beans,
  },
  {
    "name": "French Fries",
    "variants": [
      {
        "price": 0,
      },
    ],
    "featuredImage": fries,
  }
]

export default productData;
