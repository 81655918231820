import { Field, FieldArray, Form, Formik } from "formik";
import React, { useState, useContext } from "react";
import "../../../../index.css";
import classes from "./addProduct.module.css";


import axios from "../../../../requests/axios";
import routes, { store } from "../../../../requests/routes";


import AddProductContext, { createVariant } from "../../../../contexts/addProductContext.ts";
import { colors, sizes } from "../../../../assets/productData";
import VariantsList from "./variantsList/variantsList.js";

function AddProduct(props) {
  const categories = ["accessories", "clothes", "shoes", "bags", "jewelry"];
  const tags = ["headset", "earphones", "headphones", "speakers"];

  const variantKey = "colorIndex";
  const variantDictKey = colors;

  // const [variantsData, setVariantsData] = useState([]); // array of objects [{}]

  const { productData, setProductData, variantsData, setVariantsData } = useContext(AddProductContext);

  const initialValues = {
    name: "",
    category: "",
    description: "",
    tags: [],
    variants: variantsData,
  };

  const handleSubmit = async (data) => {
    console.log(variantsData[0].images[0]);


    data.shop = store
    data.featuredImage = variantsData[0].images[0];
    const prodBody = { ...data };
    prodBody.variants = createVariantsForBackend(data);

    axios.post(routes.addProduct, prodBody, { headers: { "Content-Type": "multipart/form-data" } }).then(async (result) => {
      console.log(result);
      const res = await Promise.all(
        variantsData.map(async (variant) => {
          const body = new FormData();
          body.append("variant", variantDictKey[variant[variantKey]].color);
          variant.images.forEach((image) => {
            body.append("variantImages", image);
          });
          await axios.patch(routes.addProduct + "/" + result.data._id + "/variantImg", body, { headers: { "Content-Type": "multipart/form-data" } });
        }
        )
      );
      setProductData(initialValues);
    });

  };

  const createVariantsForBackend = (data) => {
    const allVariants = []
    data.variants.forEach((variant) => {
      variant.sizes.forEach((size) => {
        if (Number(size.price) > 0 && Number(size.quantity) > 0) {
          allVariants.push({
            color: colors[variant.colorIndex].color,
            size: size.size,
            price: Number(size.price),
            quantity: Number(size.quantity),
          })
        }
      });
    });

    return allVariants;
  }


  return (
    <div className={classes.container}>
      <h2 className="title">Add Product</h2>
      <Formik
        initialValues={productData}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, setValues, onChange }) => (
          <Form className={classes.form}>

            <div className={classes.boxContainer}>
              <Field
                className="inputField"
                name="name"
                autoComplete="off"
                placeholder="Name"
                onChange={(e) => { setProductData({ ...productData, name: e.target.value }); setValues({ ...values, name: e.target.value }) }}
              />
            </div>

            <div className={classes.boxContainer}>
              <Field
                className="inputField"
                name="description"
                autoComplete="off"
                placeholder="description"
                onChange={(e) => { setProductData({ ...productData, description: e.target.value }); setValues({ ...values, description: e.target.value }) }}
              />
            </div>
            <div className={classes.boxContainer}>
              <Field
                className="inputField"
                name="subCategory"
                autoComplete="off"
                placeholder="subCategory"
                onChange={(e) => { setProductData({ ...productData, subCategory: e.target.value }); setValues({ ...values, subCategory: e.target.value }) }}
              />
            </div>
            <div className={classes.dropboxes}>
              <div className={classes.dropbox}>
                <Field as="select" name="category" className={classes.dropList}
                  onChange={(e) => { setProductData({ ...productData, category: e.target.value }); setValues({ ...values, category: e.target.value }) }}
                >
                  {categories.map((category, index) => {
                    return (
                      <option value={category} key={index} className={classes.dropItem}>
                        {category}
                      </option>
                    );
                  })}
                </Field>
              </div>

              <div className={classes.dropbox}>
                <div className={classes.selector}>
                  <Field as="select" name="tags" className={classes.dropList}
                    onChange={(e) => { setProductData({ ...productData, tags: e.target.value }); setValues({ ...values, tags: e.target.value }) }}
                  >
                    {tags.map((tag) => {
                      return (
                        <option value={tag} className={classes.dropItem}>
                          {tag}
                        </option>
                      );
                    })}
                  </Field>
                </div>
              </div>
            </div>

            <VariantsList values={values} setValues={setValues} />

            <button type="submit" className="largeBtn">
              <p>Submit</p>
            </button>
          </Form>
        )}
      </Formik>
    </div >
  );
}

export default AddProduct;
