const AboutUsData =
    {
        content: `Elzahy group is an Egyptian-Saudi-Yemeni company that provides the finest types of 
        vegetables and fruits from the best Egyptian farms. Our aim is to provide a quality that 
        meets all tastes, exceeding all expectations. We accept our customers’ suggestions to 
        develop any product according to the market requirements and customer needs. We are
        specialized in the production, packaging, trade and export of frozen vegetables and fruits.
        Since Founded in 1999 , Elzahy Group has set its vision to satisfy the consumer’s needs.
        We, proudly, provide a final product with excellent taste and carefully selected ingredients
        that conform to Egyptian, Gulf, European and American standard specifications, as our 
        customers are our partners.
        The company's products are certified by the Saudi Food and Drug Safety Authority.`
    }
    
export default AboutUsData