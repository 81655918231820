import { Route, Routes, Navigate } from "react-router-dom";
import classes from "./admin.module.css";
import AddProduct from "./products/addProduct/AddProduct";
import SideBar from "./sideBar/SideBar";
import Dashboard from "./dashboard/Dashboard";
import AdminOrders from "./orders/AdminOrders";
import Products from "./products/Products";

import AddProductContextProvider from "../../contexts/addProductContextProvider";
import NavBar from "../../layouts/nav/NavBar";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Admin = () => {
  const user = useSelector((state) => state.user);
  const Navigate = useNavigate();
  useEffect(() => {
    if (!user.isAdmin) {
      Navigate("/");
    }
  }, []);

  return (
    <>
      <NavBar />
      <div className={classes.admin}>
        <aside>
          <SideBar />
        </aside>
        <main>
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/orders"
              element={
                <h1>
                  <AdminOrders />
                </h1>
              }
            />
            <Route path="/products">
              <Route path="" element={<Products />} />
              <Route
                path="add"
                element={
                  <AddProductContextProvider>
                    <AddProduct />
                  </AddProductContextProvider>
                }
              />
            </Route>
            <Route path="/categories" element={<h1>Categories</h1>} />
            <Route path="*" element={<Navigate to="dashboard" />} />
          </Routes>
        </main>
      </div>
    </>
  );
};

export default Admin;
