import classes from "./statCard.module.css"

function StatCard(props) {

    const getAverage = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length
    const getPercentage = (arr) => ((arr[arr.length - 1] - getAverage(arr)) * 100 / getAverage(arr)).toFixed(1)

    return (
        <div
            className={classes.container}
        >
            <div className={classes.card}>
                <div className={classes.top}>
                    <img src={props.stat.icon} alt='icon' className={classes.icon} />
                    <div className={`${getPercentage(props.stat.number) > 0 ? classes.increase : classes.decrease} ${classes.percentage} w-max rounded-full h-6 px-2 flex justify-items-center text-primary font-main font-semibold text-sm`}>
                        <span>{getPercentage(props.stat.number)} %</span>
                    </div>
                </div>
                <div className={classes.bottom}>
                    <div>
                        <div className={classes.number}>{props.stat.number[props.stat.number.length - 1]}</div>
                        <div className={classes.title}>{props.stat.title}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StatCard;
