import React from "react";
import emptyOrder from "../../../../assets/imgs/emptyorder.svg";
import "../../../../index.css";
import classes from "./emptyOrder.module.css";
const EmptyOrder = (props) => {
  return (
    <div className={classes.container}>
      <img src={emptyOrder} alt="empty order" className={classes.emptyImg} />
      <div>
        <h1>There are no orders</h1>
        {!props.admin && (
          <>
            <p>You have to make online purchase first</p>
            <p>Take a look at our latest arrivals!</p>
          </>
        )}
      </div>
      {!props.admin && (
      <button className="largeBtn">
        <p className={classes.btnText}>NEW ARRIVALS</p>
      </button>
        )}
    </div>
  );
};

export default EmptyOrder;
