import classes from "./products.module.css";
import Table from "../../../generic components/Table/Table";
import "../../../index.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../../requests/axios";
import routes from "../../../requests/routes";
import Loader from "../../../layouts/loader/Loader";

function Products() {
  const [products, setProducts] = useState([]);
  const [loader, setLoader] = useState(true);

  async function getProducts() {
    try {
      const resp = await axios.get(routes.getAllShopProducts);
      setProducts(resp.data.products);
      setLoader(false);
    } catch (err) {}
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getProducts();
  }, []);

  const columns = [
    "Img",
    "Name",
    "Tags",
    "Category",
    "Variants",
    "Availability",
    "Price",
    " ",
  ];

  return (
    <div className={classes.main}>
      <h1 className="title">Products</h1>
      <Link className={classes.btn} to="add">
        <span>+</span>new product
      </Link>
      {loader ? (
        <Loader color={"#f900bf"} />
      ) : (
        <Table columns={columns} rows={products} />
      )}
    </div>
  );
}

export default Products;
