import classes from "./categorycard.module.css";

const CategoryCard = (props) => {


  function handleClick() {
    // navigate("/products/" + props.title.toLowerCase());
  }


  return (
    <div className={`${classes.container} animate__animated ${props.isVisible ? "animate__fadeInUp" : ""}`} onClick={handleClick}>
      <div className={classes.categoryImg}>
        <img
          src={props.img}
          alt="category"
        />
      </div>
      <div className={classes.details}>
        <a>{props.title}</a>
        <ul columns={props.columns}>
          {props.products.map((product, index) => (
            <li key={index}>{product}</li>
          ))}
        </ul>
      </div>

    </div>
  );
};

export default CategoryCard;
