import React from "react";
import classes from "./footer.module.css";
import FooterData from "../../assets/data/FooterData";
import facebook from "../../assets/imgs/icons/facebook.svg";
import instagram from "../../assets/imgs/icons/instagram.svg";
// import phone from "../../assets/imgs/phone.png";
// import mail from "../../assets/imgs/mail.png";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <div className={classes.data}>
        {FooterData.map((data, index) => {
          return (
            <div key={index}>
              <h3>{data.header}</h3>
              <ul>
                {data.links.map((link, index) => {
                  return (
                    <li key={index}>
                      <img src={link.icon} alt="icon" /><a>{link.title}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
      <div className={classes.newsLetter}>
        {/* <h3>News Letter</h3>
        <p>Be the first to know about our newest arrivals and special offers</p>
        <form className={classes.subscribe}>
          <input type="email" placeholder="Enter your email" />
          <button>Subscribe</button>
        </form> */}
        <h3>Follow Us</h3>
        <div className={classes.socials}>
          <a target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=61554322011167&mibextid=9R9pXO">
            <img src={facebook} alt="fb" />
          </a>
          <a target="_blank" rel="noreferrer" href="https://instagram.com/elzahyvegetables">
            <img src={instagram} alt="fb" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
