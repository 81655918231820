import { Route, Routes } from "react-router-dom";
import Footer from "./layouts/footer/Footer";
import NavBar from "./layouts/nav/NavBar";
import ForgetPasswordPage from "./pages/authentication/ForgetPasswordPage";
import HomePage from "./pages/home/homePage";
import ProductsPage from "./pages/products/ProductsPage";
import Profile from "./pages/profile/Profile";
import FindUs from "./pages/findUs/FIndUs";
// import SubscribeCard from "./generic components/subscribe/SubscribeCard";
import GenericForm from "./generic components/generic form/GenericForm";
import NotFound from "./layouts/notFound/NotFound";
import Information from "./pages/Information/Information";
import ProductDetails from "./pages/products/productsDetails/ProductDetalis";

import InfiniteScrollingBanner from "./generic components/infiniteScrollingBanner/InfiniteScrollingBanner";
import Admin from "./pages/admin/Admin";
import ContactUs from "./pages/contact us/ContactUs";

function App() {


  return (
    <>
      {/* <SubscribeCard open={open} setOpen={setOpen} /> */}
      {/* <InfiniteScrollingBanner innerText="This is a test" /> */}
      <NavBar />
      <Routes>
        {/* <Route path="/adminForm" element={<GenericForm />} />
        <Route path="/admin" element={<Admin />} /> */}
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/locations" element={<FindUs/>} />
        <Route path="/products/:id" element={<ProductsPage />} />
        <Route path="/productDetails/:id" element={<ProductDetails />} />
        <Route path="/Information" element={<Information />} />
        <Route path="/contact" element={<ContactUs />} />
        
        <Route path="/forgetPassword/:id" element={<ForgetPasswordPage />} />
        <Route path="/profile/:id/*" element={<Profile />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
