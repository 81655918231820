import phone from "../imgs/call.png";
import mail from "../imgs/mail.png";

const FooterData = [{
  "header": "Reach Out",
  "links": [
    { "title": "+20 1020293571", "link": "/faq", "icon": phone },
    { "title": "elzahyvegetables@gmail.com", "link": "/careers", "icon": mail },
  ]
},
];

export default FooterData;
