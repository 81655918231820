const chartData = [
    {
        title: "Money Transferred",
        type: "pie",
        data: [
            {
                date: "Jan",
                number: 4000,
            },
            {
                date: "Feb",
                number: 3000,
            },
            {
                date: "Mar",
                number: 2000,
            },
            {
                date: "Apr",
                number: 2780,
            },
            {
                date: "May",
                number: 1890,
            },
            {
                date: "Jun",
                number: 2390,
            },
            {
                date: "Jul",
                number: 3490,
            },
            {
                date: "Aug",
                number: 4000,
            },
            {
                date: "Sep",
                number: 3000,
            },
            {
                date: "Oct",
                number: 2000,
            },
            {
                date: "Nov",
                number: 2780,
            },
            {
                date: "Dec",
                number: 1890,
            },
        ]
    },
    {
        title: "Money Transferred",
        type: "bar",
        data: [
            {
                date: "Jan",
                number: 4000,
            },
            {
                date: "Feb",
                number: 3000,
            },
            {
                date: "Mar",
                number: 2000,
            },
            {
                date: "Apr",
                number: 2780,
            },
            {
                date: "May",
                number: 1890,
            },
            {
                date: "Jun",
                number: 2390,
            },
            {
                date: "Jul",
                number: 3490,
            },
            {
                date: "Aug",
                number: 4000,
            },
            {
                date: "Sep",
                number: 3000,
            },
            {
                date: "Oct",
                number: 2000,
            },
            {
                date: "Nov",
                number: 2780,
            },
            {
                date: "Dec",
                number: 1890,
            },
        ]
    },
    {
        title: "total transactions",
        type: "line",
        data: [
            {
                date: "Jan",
                number: 4000,
            },
            {
                date: "Feb",
                number: 3000,
            },
            {
                date: "Mar",
                number: 2000,
            },
            {
                date: "Apr",
                number: 2780,
            },
            {
                date: "May",
                number: 1890,
            },
            {
                date: "Jun",
                number: 2390,
            },
            {
                date: "Jul",
                number: 3490,
            },
            {
                date: "Aug",
                number: 4000,
            },
            {
                date: "Sep",
                number: 3000,
            },
            {
                date: "Oct",
                number: 2000,
            },
            {
                date: "Nov",
                number: 2780,
            },
            {
                date: "Dec",
                number: 1890,
            },
        ]
    },

]

export default chartData;