import { Field } from "formik";
import React, { useState, useContext } from "react";
import SizeButtonFilter from "../../../../../../generic components/filter/filter components/Filter buttons/SizeButton";
import "../../../../../../index.css";
import classes from "./addSize.module.css";

import AddProductContext from "../../../../../../contexts/addProductContext.ts";

function AddSize(props) {
  const [sizeSelected, setSelected] = useState(false);
  const [price, setPrice] = useState(null);
  const [quantity, setQuantity] = useState(null);


  const { variantsData, setVariantsData } = useContext(AddProductContext);
  const setSize = (newData) => {
    variantsData[props.variantIndex].sizes[props.sizeIndex] = newData;
    setVariantsData([...variantsData]);
  }



  return (
    <div className={classes.container}>
      <div className={classes.sizeSection}>
        <SizeButtonFilter
          size={props.size}
          selected={sizeSelected}
          handleSelected={() => { }}
        />
        <div className={classes.fieldWrapper}>
          <Field
            className={`inputField ${classes.field}`}
            name={props.size + "Price"}
            autoComplete="off"
            placeholder="price"
            type="number"
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              setPrice(e.target.value);
            }}
            onBlur={() => {
              setSize({
                size: props.size,
                price: price,
                quantity: quantity,
              })
            }}
          />
        </div>
        <div className={classes.fieldWrapper}>
          <Field
            className={`inputField ${classes.field}`}
            name={props.size + "quantity"}
            autoComplete="off"
            placeholder="quantity"
            type="number"
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              setQuantity(e.target.value);
            }}
            onBlur={() => {
              setSize({
                size: props.size,
                price: price,
                quantity: quantity,
              })
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default AddSize;
