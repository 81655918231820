import React, { useState, useEffect } from "react";
import classes from "./exploreourcategories.module.css";
import CategoryCard from "./categorycard/CategoryCard";
import CategoryData from "../../../assets/data/categoriesData";
import useOnScreen from "../../../hooks/useOnScreen.ts";

const ExploreOurCategories = () => {
  const categoryRef = React.useRef(null);
  const isVisible = useOnScreen(categoryRef);

  return (
    <div className={classes.container}>
      <div className={classes.secHeader}>
        <h3>Our Categories</h3>
        {/* <h4>Categories</h4> */}
        {/* <img src={arrow} alt="arrow" className={classes.arrow}></img>
        <img src={star} alt="star" className={classes.star}></img> */}
      </div>
      <div ref={categoryRef} className={classes.body}>
        {CategoryData.map((category, index) => (
          <CategoryCard key={index} title={category.title} isVisible={isVisible}
            columns={category.columns}
            img={category.img} products={category.products} />
        ))}
      </div>
    </div>
  );
};

export default ExploreOurCategories;
