import { FieldArray } from "formik";
import React, { useContext } from "react";

import classes from "../addProduct.module.css";
import AddProductContext, { createVariant } from "../../../../../contexts/addProductContext.ts";
import AddVariants from "../add variants/AddVariants.js";


export default function VariantsList(props) {

    const { variantsData, setVariantsData } = useContext(AddProductContext);

    return (<FieldArray
        name={`variants`}
        render={() => {
            const variants = props.values.variants;
            return (
                <div className={classes.section2}>
                    {variants && variants.length > 0
                        ? variants.map((_, variantIndex) => (
                            <div key={variantIndex} className={classes.chooseVariants}>
                                <AddVariants variantNo={variantIndex} />
                                <button
                                    className={classes.remove}
                                    type="button"
                                    onClick={() => {
                                        props.setValues({
                                            ...props.values, variants: variantsData.filter(
                                                (_, index) => index !== variantIndex
                                            )
                                        });
                                        setVariantsData((oldVariants) => oldVariants.filter(
                                            (_, index) => index !== variantIndex
                                        ));
                                    }
                                    }
                                >
                                    delete
                                </button>
                            </div>
                        ))
                        : null}

                    <div className={classes.addVariant}>
                        <p>Add a Variant</p>
                        <button
                            className={classes.addBtn}
                            type="button"
                            onClick={() => setVariantsData([...variantsData, createVariant()])}
                        >
                            +
                        </button>
                    </div>
                </div>
            );
        }}
    />)
}