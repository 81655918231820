import React, { useState, useEffect } from "react";
import classes from "./navbar.module.css";
import { NavLink } from "react-router-dom";
import ShopButton from "./shopButton/ShopButton";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingIcon from "@mui/icons-material/ShoppingBagOutlined";
import FavoriteIcon from "@mui/icons-material/FavoriteBorderOutlined";
import PersonIcon from "@mui/icons-material/PersonOutlined";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import RoomIcon from '@mui/icons-material/Room';
import Overlay from "../../generic components/overlay/Overlay.tsx";
import Cart from "../../generic components/cart/cart";
import CartTitle from "../../generic components/cart/cartTitle";
import { useSelector } from "react-redux";
import logoPic from "../../assets/imgs/nav/Company_Name.jpg";

const NavBar = () => {
  const user = useSelector((state) => state.user);
  const cart = useSelector((state) => state.cart);
  const logged = user.loggedIn;
  const [showCart, setShowChart] = useState(false);
  return (
    <nav className={classes.nav}>
      {/* <div className={classes.left}>
        <h4>
          <ShopButton />
        </h4>
        <SearchIcon fontSize="large" /> <input placeholder="Search" />
      </div> */}
      <NavLink to="/" className={classes.brand}>
        <img src={logoPic} alt="logo" />
      </NavLink>
      {/* <div className={classes.icons}>
        <NavLink to="/locations" className={classes.loc}>
          <RoomIcon fontSize="large" />
        </NavLink>
        <div className={classes.profile}>
          {!logged ? (
            <NavLink to="/login">
              <p>Login</p>
            </NavLink>
          ) : user.isAdmin ? (
            <NavLink to={"/admin/dashboard"}>
              <AdminPanelSettingsIcon fontSize="large" />
            </NavLink>
          ) : (
            <NavLink to={"/profile/" + user.id + "/orders"}>
              <PersonIcon fontSize="large" />
            </NavLink>
          )}
        </div>
        <NavLink to={"/profile/"+user.id+"/favorites"}>
          <FavoriteIcon fontSize="large" />
        </NavLink>
        <div className={classes.cartIcon}>
          <ShoppingIcon fontSize="large" onClick={() => setShowChart(true)} />
          {cart.cartQuantity > 0 && (
            <h6 className={classes.cartItemsNumber}>{cart.cartQuantity}</h6>
          )}
        </div>
      </div>

      <Overlay
        header={<CartTitle title={"My Cart"} />}
        open={showCart}
        onClose={() => setShowChart(false)}
        position="right"
      >
        <Cart
          includeCheckout={true}
          items={cart.items}
          closeOverlay={() => setShowChart()}
        />
      </Overlay> */}
    </nav>
  );
};

export default NavBar;
