
export const sizes = [
    { size: "M" },
    { size: "L" },
    { size: "XL" },
    { size: "XXL" },
    { size: "XXXL" },
];


export const colors = [
    { color: "red" },
    { color: "white" },
    { color: "black" },
    { color: "blue" },
    { color: "green" },
    { color: "pink" },
];