import vegetables from "../imgs/categories/khodarmeshakel.jpg";
import fruits from "../imgs/categories/mango.jpg"

const CategoryData = [
  {
    title: "Vegetables",
    img: vegetables,
    products: [
      "Frozen fries",
      "French fries",
      "Green Peas",
      "Peas&Carrots",
      "Mixed Vegetables ",
      "Green Beans",
      "Okra Extra",
      "Okra Zero",
      "Okra Fine",
      "Molokhia",
      "Artichoke",
    ],
    columns: 2,
  },
  {
    title: "Fruits",
    img: fruits,
    products: [
      "Mango Slices",
      "Strawberry",
      "Pomegranate",
    ],
    columns: 1,
  },
];

export default CategoryData;
