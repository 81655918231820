import React, { useState, useEffect } from "react";
import classes from "./home.module.css";
import Intro from "./intro/Intro";
import ProductList from "../../generic components/Product List/ProductList";
import WhatsappButton from "./whatsappButton/WhatsappButton";
import axios from "../../requests/axios";
import routes from "../../requests/routes";
import AboutUs from "./aboutus/AboutUs";
import productData from "../../assets/data/productData";
import ExploreOurCategories from "./exploreCategories/ExploreOurCategories";
import NewArrivals from "./newArrivals/NewArrivals";
import ReviewList from "../../generic components/Review List/ReviewList";
import ReviewsData from "../../assets/data/ReviewsData";
import introImg from "../../assets/brand/brand.png";
import introImgDesktop from "../../assets/brand/brand-desktop.png";
import CarouselHeroSection from "../../generic components/HeroSections/CarouselHeroSection/CarouselHeroSection";
import Subscribe from "./subscribe/Subscribe";
import "animate.css";
import products from "../../assets/data/productData";


const HomePage = () => {



  return (
    <>
      <div className={classes.container}>
        {/* <Intro /> */}
        <img className={`${classes.introImgMobile} animate__animated animate__fadeIn`} src={introImg} alt="intro" />
        <img className={`${classes.introImgDesktop} animate__animated animate__fadeIn`} src={introImgDesktop} alt="introDesktop" />
        {/* <CarouselHeroSection /> */}
        {/* Categories List of buttons */}
        {/*Best Sellers List Of Cards */}
        <AboutUs variant={2} />
        <ExploreOurCategories />
        <ProductList title={true} products={products} />
        {/* <NewArrivals /> */}
        {/* <ReviewList products={ReviewsData} /> */}
        <div className={classes.subscribe}>
          <Subscribe />
        </div>
        {/*Reviews What Customers say*/}
        {/* <WhatsappButton /> */}
      </div>
    </>
  );
};

export default HomePage;
