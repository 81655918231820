import React from "react";
import classes from "./aboutus.module.css";
import about from "../../../assets/brand/logo.png";
import AboutUsData from "../../../assets/data/aboutusData";
const AboutUs = (props) => {
  return (
    <div
      className={`${props.variant === 2 ? classes.container2 : classes.container1
        }`}
    >
      <div className={classes.variant2}>
        <img src={about} alt="img" />
      </div>
      <div
        className={`${props.variant === 2 && classes.visibleText}  ${classes.text
          }`}
      >
        <h1>ABOUT US</h1>
        <p>{AboutUsData.content}</p>
      </div>
    </div>
  );
};

export default AboutUs;
